<template>
  <div class="mainBox">
    <div class="title">
      <h1>RIFA DE NÚMEROS</h1>
    </div>
    <div class="header">
      <img src="../assets/logo.png" alt="LogoTeotitlan" class="logo">
      <h1>Restauración del templo católico de Teotitlán del Valle, Oaxaca.</h1>
    </div>
    <div class="imagesBox">
      <img src="../assets/Information.png" alt="information" class="imgInfo">
      <img src="../assets/premios.png" alt="premios" class="imgInfo">
    </div>
    <div class="instructions">
      <h2>Instrucciones de compra de numeros</h2>
      <p>1.- Ingresa los datos que se te solicitan en el formulario.</p>
      <p>2.- Selecciona el número o números que sean de tu agrado.</p>
      <p>3.- Después de seleccionar los números, dirígete hacia abajo de la tabla <br>
        y haz clic en el botón de "Comprar" para finalizar tu compra.
      </p>
      <p class="price">Precio por boleto: $100</p>
    </div>
    <div class="ticketsBox">
      <div class="inputGroup">
        <label for="Nombre" >Nombre Completo:</label>
        <input type="text" class="inputInformation" v-model="name">
      </div>
      <div class="tableContainer">
        <div class="numberTable">
          <div 
            v-for="number in numbers" 
            :key="number" 
            @click="toggleNumber(number)" 
            :class="{ 'selected': isSelected(number) }" 
            class="numberCell"
          >
            {{ number }}
          </div>
        </div>
        <p 
          class="numbers"
        >
          Números seleccionados: {{ selectedNumbers.join(', ') }}
        </p>
        <p class="numbers">
          Total: $ {{ selectedNumbers.length * 100 }}
        </p>
      </div>
      <div class="buttonBox">
        <button class="buttonTable" @click="sendData()">Comprar</button>
      </div>
    </div>
  </div>
</template>

<script>
import http from '../plugins/http'
import { v4 as uuidv4 } from 'uuid';

export default {
  data() {
    return {
      name: '',
      selectedNumbers: [],
      numbers: []
    };
  },
  mounted() {
    http.get('/tickets')
      .then(res => {
        let items = JSON.parse(res.data.body).Items
        let numbersDB = items.map(item => parseInt(item.number))
        for (let i = 2001; i <= 2500; i++) {
          if (!numbersDB.includes(i)) {
            this.numbers.push(i);
          }
        }
      })
      .catch(res => console.log(res))
  },
  methods: {
    toggleNumber(number) {
      if (this.isSelected(number)) {
        this.selectedNumbers = this.selectedNumbers.filter(n => n !== number);
      } else {
        this.selectedNumbers.push(number);
      }
    },
    isSelected(number) {
      return this.selectedNumbers.includes(number);
    },
    async sendData() {
      let ticketId = uuidv4();

      if(this.selectedNumbers.length > 1) {
        let entriesCopy = []
        for(let i = 0; i < this.selectedNumbers.length; i++) {
          let id = uuidv4();
          entriesCopy.push({
            id,
            name: this.name,
            number: `${this.selectedNumbers[i]}`
          })
        }
        await http.post('/tickets', {
            id: ticketId,
            name: this.name,
            multipleEntries: true,
            number: entriesCopy
          })
            .then(response => {
              console.log(response)
            })
            .catch(err => {
              console.error(err)
            })
      }else {
        await http.post('/tickets', {
            id: ticketId,
            name: this.name,
            number: `${this.selectedNumbers}`,
            multipleEntries: false,
          })
      }
      this.$router.push({
        path: '/payPage',
        query: {
          name: this.name,
          numbers: this.selectedNumbers.join(', ')
        }
      })
    }
  }
}
</script>

<style scoped> 
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@1,700&display=swap');

.mainBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}
.title{
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.title h1 {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: italic;
  font-size: 80px;
}
.header {
  width: 100%;
  height: 200px;
  display: flex;
  background-color: black;
  color: white;
  justify-content: center;
  align-items: center;
}
.imgInfo {
  width: 90%;
  height: auto;
}
.header h1 {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-style: normal;
}
.logo {
  padding-right: 50px;
  width: 200px;
  height: 200px;
}
.imagesBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
}
.imagesBox img {
  border-radius: 20px;
}
.instructions{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.instructions h2 {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: italic;
  font-size: 80px;
}
.instructions p {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: italic;
  font-size: 45px;
  text-align: center;
}
.price {
  font-size: 60px !important;
  font-family: "Ubuntu", sans-serif !important;
  font-weight: 700 !important;
  font-style: italic !important;
}
.ticketsBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;
  
}
.inputGroup {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
}
.ticketsBox label {
  margin-bottom: 5px;
  position: relative;
  font-size: 25px;
}
.inputInformation {
  width: 500px;
  height: 25px;
  padding: 10px 20px;
  color: black;
  border: 2px solid black;
  border-radius: 10px;
  font-size: 20px;
}
.tableContainer {
  padding-top: 30px;
  max-width: 75%;
  margin: 0 auto;
  max-width: 100%;
}

.numberTable {
  display: flex;
  flex-wrap: wrap;
}

.numberCell {
  width: 80%;
  flex-basis: calc(100% / 38); 
  padding: 10px;
  text-align: center;
  border: 1px solid #ddd;
  font-size: 20px;
  background-color: rgb(227, 227, 86);
}

.numberCell:hover {
  background-color: #f2f2f2;
  cursor: pointer;
}

.numberCell.selected {
  background-color: red;
  color: #fff;
}
.numbers {
  font-size: 35px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: italic;
}
.buttonBox {
  padding: 55px
}
.buttonTable {
  width: 300px;
  height: 60px;
  border-radius: 10px;
  background-color: rgb(61, 60, 60);
  border: 2px solid black;
  color: white;
  font-size: 20px;
  cursor: pointer;
}
.buttonTable:hover {
  transition: background-color 0.3s ease;
  background-color: black;
}

@media only screen and (max-width: 768px) {
  .title h1 {
    font-size: 60px;
  }
  .header h1 {
    font-size: 24px;
  }
  .logo {
    width: 150px;
    height: 150px;
    padding-right: 20px;
  }
  .imagesBox {
    padding-top: 20px;
  }
  .instructions h2 {
    font-size: 60px;
  }
  .instructions p {
    font-size: 30px;
  }
  .price {
    font-size: 40px !important;
  }
  .ticketsBox {
    padding-top: 20px;
  }
  .inputInformation {
    width: 100%;
  }
  .tableContainer {
    max-width: 100%;
  }
  .numberCell {
    flex-basis: calc(100% / 8); 
    font-size: 16px;
  }
  .numbers {
    font-size: 25px;
  }
  .buttonBox {
    padding: 30px;
  }
}

@media only screen and (max-width: 480px) {
    .title h1 {
        font-size: 40px;
    }

    .header h1 {
        font-size: 24px;
    }

    .imagesBox img {
        width: 150px;
        height: auto;
    }

    .instructions h2 {
        font-size: 40px;
    }

    .instructions p {
        font-size: 18px;
    }

    .price {
        font-size: 30px !important;
    }

    .inputInformation {
        font-size: 16px;
        width: 100%;
        max-width: 300px;
    }

    .numberCell {
        font-size: 16px;
    }

    .numbers {
        font-size: 20px;
    }

    .buttonTable {
        font-size: 18px;
        width: 100%;
        max-width: 200px;
    }
    }
</style>