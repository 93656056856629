<template>
  <div class="mainBox">
    <div class="header">
      <img src="../assets/logo.png" alt="LogoTeotitlan" class="logo">
      <h1>Restauración del templo católico de Teotitlán del Valle, Oaxaca.</h1>
    </div>
    <div class="iconBox">
      <svg-icon type="mdi" :path="path" class="checkIcon" size = '120px'></svg-icon>
    </div>
    <div class="informationBox">
      <h1>En hora buena {{ name }}, has apartado los boletos :</h1>
      <p>{{ numbers }}</p>
      <p>
        Tienes 24 horas para hacer el pago a cualquiera de las dos opciones 
        de pago que se te mostrarán a continuación, de lo contrario, los 
        números volverán a estar disponibles.
      </p>
    </div>
    <div class="boxAccount">
      <img src="../assets/bancoAzteca.png" alt="Cuenta Banco Azteca" class="imagePay">
      <img src="../assets/payPal.png" alt="PayPal" class="imagePay">
    </div>
    <div class="contactBox">
      <h1>
        Al hacer tu pago, por favor, envía el comprobante a cualquiera<br> 
        de los siguientes números vía WhatsApp con el nombre del  <br>
        comprador, los números seleccionados y captura del depósito.
      </h1>
      <h2>Números de contacto:</h2>
      <p>- Bulmaro Lazo Vicente (El bolas) :  +52 951 577 5197</p>
      <p>- Arnulfo Martinez Ruiz (El guerro):  +52 951 470 9539</p>
      
    </div>

  </div>
</template>

<script>
import SvgIcon from '@jamescoyle/vue-icon';
import { mdiCheckCircle } from '@mdi/js';

export default {
  name: "my-component",
  components: {
    SvgIcon
  },
  data() {
    return {
       path: mdiCheckCircle,
       name:'',
       numbers: []
    }
  },
  mounted() {
    this.name = this.$route.query.name;
    this.numbers = this.$route.query.numbers
  }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;500;700&display=swap');

.mainBox {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.header {
  width: 100%;
  height: 200px;
  display: flex;
  background-color: black;
  color: white;
  justify-content: center;
  align-items: center;
}

.imagePay {
  width: 50%;
  height: auto;
}
.header h1 {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-style: normal;
}
.logo {
  padding-right: 50px;
  width: 200px;
  height: 200px;
}
.iconBox {
  display: flex;
  padding-top: 150px;
}
.checkIcon {
  color: green;
}
.informationBox {
  display: flex;
  align-items: center;
  text-align: justify;
  flex-direction: column;
}
.informationBox h1 {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: normal;;
  font-size: 40px;
}
.informationBox p {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 30px;
}
.boxAccount {
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
}
.boxAccount img{
  padding-left: 30px;
}
.contactBox {
 display: flex;
 flex-direction: column;
 padding-bottom: 90px;
}
.contactBox h1 {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 30px;
}
.contactBox h2 {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: normal;;
  font-size: 30px;
}
.contactBox p {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 25px;
}

@media only screen and (max-width: 768px) {
  .header {
    height: 150px;
  }
  .logo {
    width: 150px;
    height: 150px;
    padding-right: 20px;
  }
  .iconBox {
    padding-top: 100px;
  }
  .informationBox h1 {
    font-size: 20px;
  }
  .informationBox p {
    font-size: 15px;
    width: 90%;
  }
  .boxAccount img {
    padding-left: 14px;
  }
  .contactBox h1,
  .contactBox h2 {
    font-size: 20px;
  }
  .contactBox p {
    font-size: 15px;
  }
  .imagePay {
    width: 80%;
  }
  .boxAccount {
  flex-direction: column;
  }
}

@media only screen and (max-width: 480px) {
  .header {
    height: 150px;
  }
  .header h1 {
    font-size: 24px;
  }
  .logo {
    width: 150px;
    height: 150px;
    padding-right: 20px;
  }
  .iconBox {
    padding-top: 100px;
  }
  .informationBox h1 {
    font-size: 20px;
  }
  .informationBox p {
    font-size: 15px;
  }
  .boxAccount img {
    padding-left: 15px;
  }
  .contactBox h1,
  .contactBox h2 {
    font-size: 20px;
  }
  .contactBox p {
    font-size: 15px;
  }
  .boxAccount {
  flex-direction: column;
  }
}
</style>