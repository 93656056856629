import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomePage.vue'
import payPage from '@/views/payPage.vue';

const routes = [
      {
        path: '/',
        name: 'Home',
        component: HomeView
      },
      {
        path: '/payPage',
        name: 'Pay',
        component: payPage
      },
     
]

const router = createRouter({
    history: createWebHistory(),
    routes
})
    
  
  export default router
  