<template>
  <RouterView></RouterView>
</template>

<script>
import { RouterView } from 'vue-router'

export default {
  name: 'App',
  components: {
    RouterView
  }
}

</script>

<style>
body {
  margin: 0;
  padding: 0;
}

</style>
